<template>
  <img v-if="image" class="ben" :src="image.src" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "shopping-list-image",
  props: {
    imgSrc: Number
  },
  computed: {
    ...mapGetters("image", {
      shopOurImages: "shopOurImages"
    }),
    image() {
      if (this.imgSrc == null) {
        return null;
      }
      return this.shopOurImages(this.imgSrc);
    }
  },

  mounted() {
    if (this.imgSrc == null) {
      return;
    }
    this.$store.dispatch("image/loadShopOurImg", { imageId: this.imgSrc });
  }
};
</script>
