<template>
  <div class="w-100">
    <div class="cf">
      <div class="karla fl w-10">
        <!-- Show  -->
        <p
          class="btn-link no-underline b black"
          v-show="!show"
          v-on:click="onShow"
        >
          +
        </p>
        <!-- Hide  -->
        <p
          class="btn-link no-underline b black"
          v-show="show"
          v-on:click="onClose"
        >
          -
        </p>
      </div>

      <div class="karla fl w-40 tracked-light">
        <p>{{ section.product.type }}</p>
      </div>

      <div class="karla fl w-40 tracked-light">
        <p>{{ section.product.used_for }}</p>
      </div>

      <div class="karla fl w-10 tracked-light">
        <p>{{ section.product.piece }} {{ section.product.unit }}</p>
      </div>
    </div>

    <div v-show="show" class="w-100 fl i karla tracked-light">
      <div class="cf">
        <div class="karla fl w-10 tracked-light">&nbsp;</div>

        <div class="karla fl w-40 tracked-light">
          <attribute
            :attr="section.product.name"
            translation="shopping_list.attribute.name"
          />

          <attribute
            :attr="section.product.model"
            translation="shopping_list.attribute.model"
          />

          <attribute
            :attr="section.product.package_size"
            translation="shopping_list.attribute.package_size"
          />

          <attribute
            :attr="section.product.manufacturer"
            translation="shopping_list.attribute.manufacturer"
          />

          <attribute
            :attr="section.product.place_of_purchase"
            translation="shopping_list.attribute.place_of_purchase"
          />

          <attribute
            :attr="section.product.item_number"
            translation="shopping_list.attribute.item_number"
          />

          <hyperlink
            :href="section.product.hyperlink"
            translation="shopping_list.attribute.hyperlink"
          />
        </div>

        <div class="karla fl w-40 tracked-light">
          <attribute
            :attr="section.product.size"
            translation="shopping_list.attribute.size"
          />

          <attribute
            :attr="section.product.color"
            translation="shopping_list.attribute.color"
          />

          <attribute
            :attr="section.product.thickness"
            translation="shopping_list.attribute.thickness"
          />

          <attribute
            :attr="section.product.angle"
            translation="shopping_list.attribute.angle"
          />

          <attribute
            :attr="section.product.diameter"
            translation="shopping_list.attribute.diameter"
          />

          <attribute
            :attr="section.product.voltage"
            translation="shopping_list.attribute.voltage"
          />
        </div>

        <div class="karla fl w-10 tracked-light">&nbsp;</div>

        <div class="karla fl w-100">
          <section-image :imgSrc="section.product.img_src" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Attribute from "./Attribute.vue";
import Hyperlink from "./Hyperlink.vue";
import Image from "./Image.vue";
export default {
  name: "shopping-list-section",
  props: {
    section: Object,
    index: Number
  },
  computed: {
    selectedIndex: {
      get() {
        return this.$store.state.shoppinglist.show;
      },
      set(show) {
        this.$store.commit("shoppinglist/setShow", show);
      }
    },
    show() {
      return this.index == this.selectedIndex;
    }
  },
  methods: {
    onClose() {
      this.selectedIndex = null;
    },
    onShow() {
      this.selectedIndex = this.index;
    }
  },
  components: {
    Attribute,
    Hyperlink,
    "section-image": Image
  }
};
</script>
