<template>
  <div class="roboto sans-serif tracked fl dim piiia-grey">
    <button v-on:click="onCategoryClick(category.id)">
      <font-awesome-icon
        :prefix="category.icon_prefix"
        :icon="category.icon_name"
        class="f3"
      />
      <span class="f7 fw6 ttu lh-copy db">{{ category.name }}</span>
    </button>
  </div>
</template>

<script>
//import { timeAgo } from "../../util/filters";

export default {
  name: "shopping-list-category",
  props: ["category"],
  // http://ssr.vuejs.org/en/caching.html#component-level-caching
  //	serverCacheKey: ({ category: { id, __lastUpdated, time } }) => {
  //		return `${id}::${__lastUpdated}::${timeAgo(time)}`;
  //	},
  methods: {
    onCategoryClick(categoryId) {
      this.$store.commit("shoppinglist/setCategoryId", categoryId);
    }
  }
};
</script>

<style lang="stylus"></style>
