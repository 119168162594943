<template>
  <section class="w-100 f6-ns f7 mt0-l mt4 ph4-l ph3-ns ph1">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4-m ph1 pv3">
        <div v-if="!authenticated" class="loading">Loading...</div>
        <div v-else>
          <div v-if="loading" class="loading">Loading...</div>

          <div v-else-if="error" class="error">{{ error }}</div>

          <div v-else>
            <div>
              <div v-if="product" class="tc pt4">
                <div class="ba b--light-gray bw2">
                  <h1 class="f2 ttu vidaloka mb2-l mb2 pt0">
                    {{ product.name }}
                  </h1>
                  <span class="f5 roboto sans-serif i db pb3 piiia-grey">{{
                    $t("product.type_" + product.type.toLowerCase())
                  }}</span>
                </div>
              </div>

              <!-- Category -->
              <div class="w-100 pv4" v-if="categories.length > 0">
                <div class="flex flex-wrap justify-around">
                  <category
                    v-for="category in categories"
                    :key="category.category.id"
                    :category="category.category"
                  />
                </div>
              </div>

              <!-- Section -->
              <div class="w-100 cf" v-if="sections.length > 0">
                <ssection
                  v-for="(section, index) in sections"
                  :key="section.product.id"
                  :index="index"
                  :section="section"
                ></ssection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Category from "../components/shoppinglist/Category.vue";
import Section from "../components/shoppinglist/Section.vue";

export default {
  name: "shopping-list-view",
  components: { Category, ssection: Section },
  methods: {
    fetchShoppingList() {
      return this.$store.dispatch("shoppinglist/loadShoppingList", {
        sku: this.$route.params.sku,
        cookie: this.$cookie,
        language: this.$route.params.lang
      });
    }
  },
  computed: {
    ...mapState({
      shoppinglist: state => state.shoppinglist.shoppinglist,
      sections: state => state.shoppinglist.sections,
      product: state => state.shoppinglist.product
    }),
    ...mapGetters("shoppinglist", {
      categoryId: "categoryId",
      error: "error",
      loading: "loading",
      categories: "categories"
    }),
    ...mapGetters("auth", {
      authenticated: "authenticated"
    })
  },
  created() {},
  mounted() {
    if (!this.authenticated) {
      this.$router.push({ name: "signIn" });
    } else {
      this.fetchShoppingList();
    }
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchShoppingList",
    categoryId(newValue, oldValue) {
      this.$store.dispatch("shoppinglist/toCategory", {
        cookie: this.$cookie,
        language: this.$route.params.lang
      });
    },
    authenticated(newValue, oldValue) {
      if (newValue) {
        // signed-in -> authenticated
        return;
      }
      this.$router.push({ name: "signIn" });
    }
  }
};
</script>

<style lang="stylus"></style>
