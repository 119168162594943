<template>
  <a v-if="href" v-bind:href="href" target="_blank">{{ $t(translation) }}</a>
</template>

<script>
export default {
  name: "shopping-list-hyperlink",
  props: {
    href: String,
    translation: String
  }
};
</script>
